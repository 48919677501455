import { StyledHeader, Nav, Image, Logo } from "./styles/Header.styled";
import { Flex } from "./styles/Flex.styled";
import { Button } from "./styles/Button.styled";
import { Container } from "./styles/Container.styled";
import { FaPhone, FaMapMarkerAlt } from "react-icons/fa";

export default function Header() {
  return (
    <StyledHeader>
      <Container>
        <Nav>
          {/* ?  import Logo to make work */}

          <Logo src="./images/novatechguru-logo.svg" all="logo" width={200} />

          <Button>
            <a
              href="https://www.google.com/maps/place/Novatech/@37.9636062,-122.5079386,17z/data=!4m15!1m8!3m7!1s0x80859a3ac4be31b9:0x37fc673d5ce67c32!2s88+Belvedere+St+%23+209,+San+Rafael,+CA+94901!3b1!8m2!3d37.9636062!4d-122.5053637!16s%2Fg%2F11qp4dcm82!3m5!1s0x80859bb10a950f75:0x4f429cb731e8f06d!8m2!3d37.9634945!4d-122.5054679!16s%2Fg%2F11vx64dscg?entry=ttu"
              target="_blank?"
            >
              <FaMapMarkerAlt />
              Find us
            </a>
          </Button>
          <Button>
            <a href="tel:4152155740">
              <FaPhone /> Call now
            </a>
          </Button>
        </Nav>
        <Flex>
          <div>
            <h1>Affordable Tech Repairs</h1>
            <p>Building a Stronger Community!</p>
            <Button bg="#ff0099" color="#fff">
              Se Habla Español.
            </Button>
          </div>

          <Image src="./images/computer-repair.png" alt="image" />
        </Flex>
      </Container>
    </StyledHeader>
  );
}
