import React from "react";
import SocialIcons from "./SocialIcons";
import { Flex } from "./styles/Flex.styled";
import { Container } from "./styles/Container.styled";
import { StyleFooter } from "./styles/Footer.styled";
import { FaPhone } from "react-icons/fa";

export default function Footer() {
  return (
    <StyleFooter>
      <Container>
        <img
          src="./images/novatechguru-logo.svg"
          alt=""
          width={150}
          className="footer-logo"
        />

        <Flex>
          <ul>
            <li>
              <a
                href="https://www.google.com/maps/place/Novatech/@37.9636062,-122.5079386,17z/data=!4m15!1m8!3m7!1s0x80859a3ac4be31b9:0x37fc673d5ce67c32!2s88+Belvedere+St+%23+209,+San+Rafael,+CA+94901!3b1!8m2!3d37.9636062!4d-122.5053637!16s%2Fg%2F11qp4dcm82!3m5!1s0x80859bb10a950f75:0x4f429cb731e8f06d!8m2!3d37.9634945!4d-122.5054679!16s%2Fg%2F11vx64dscg?entry=ttu"
                target="_blank?"
              >
                88 Belvedere st. <br />
                suite 209 Second Floor <br />
                San Rafael CA 94901
              </a>
            </li>
            <li>
              <p>
                <a href="tel:4152155740">
                  {" "}
                  <FaPhone />
                  1(415)215-57-40
                </a>
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <a href="#our-mission">about us</a>
            </li>
            <li>
              <a href="mailto:novatech2528@gmail.com"> Contact Us</a>
            </li>
          </ul>

          {/* Social icons  */}
          <SocialIcons />
          <p>&#169;copy 2024 novatechguru. All rights reserved</p>
        </Flex>
      </Container>
    </StyleFooter>
  );
}
