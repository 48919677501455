import React from "react";
import { StyleCard } from "./styles/Card.styled";

export default function Card() {
  return (
    // if id is even then true pass row-reverse
    <>
      <StyleCard>
        {/* flex item  */}
        <div>
          <h2 id="our-mission">Our Mission</h2>
          <p>
            Our mission at Novatech is simple: to deliver exceptional repair
            services with a focus on quality, reliability, and customer
            satisfaction, and maintaining transparent communication every step
            of the way.
          </p>
        </div>
        {/* flex item second  */}
        <div>
          <img src="./images/our_mision.png" alt="" />
        </div>
      </StyleCard>

      <StyleCard>
        <div>
          <img src="./images/services.png" alt="" />
        </div>
        {/* flex item  */}
        <div>
          <h2>Our Services</h2>
          <ul>
            <li>Screen Replacement</li>
            <li>Battery Replacement</li>
            <li>Water Damage Treatment</li>
            <li>Charging Port Treatment</li>
            <li>Bugs Fixes and Virus Removal</li>
            <li>Data Recovery</li>
          </ul>
        </div>
        {/* flex item second  */}
      </StyleCard>

      <StyleCard>
        {/* flex item  */}
        <div>
          <img src="./images/hours.png" alt="hours" />
        </div>
        <div>
          <h2>Hours</h2>
          <p>
            <b>Tuesday - Sunday</b>
            <br />
            <em>9:00am - 5:00pm</em> <br />
            <b>closed on Mondays</b>
          </p>
        </div>
        {/* flex item second  */}
      </StyleCard>

      <StyleCard>
        {/* flex item  */}
        <div>
          <h2>Visit Us</h2>
          <address>
            <p>
              <a
                href="https://www.google.com/maps/place/Novatech/@37.9636062,-122.5079386,17z/data=!4m15!1m8!3m7!1s0x80859a3ac4be31b9:0x37fc673d5ce67c32!2s88+Belvedere+St+%23+209,+San+Rafael,+CA+94901!3b1!8m2!3d37.9636062!4d-122.5053637!16s%2Fg%2F11qp4dcm82!3m5!1s0x80859bb10a950f75:0x4f429cb731e8f06d!8m2!3d37.9634945!4d-122.5054679!16s%2Fg%2F11vx64dscg?entry=ttu"
                target="_blank?"
              >
                88 Belvedere st suite 209, San Rafael CA 94901 Second Floor,
                United States, California
              </a>
            </p>
          </address>
        </div>
        {/* flex item second  */}
        <div>
          <img src="./images/novatech-map.png" alt="location" />
        </div>
      </StyleCard>
    </>
  );
}
