import React from "react";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { StyledSocialIcons } from "./styles/SocialIcons.styled";
export default function SocialIcons() {
  return (
    <StyledSocialIcons>
      <li>
        <a
          href="https://www.facebook.com/profile.php?id=61558397871935"
          target="_blank?"
        >
          <FaFacebook />
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/benjaminrojas79/?fbclid=IwZXh0bgNhZW0CMTAAAR0NCmM398RS5Xj-_EQ8mLI2lwhFSJ_Sf6N0RrkMl5yvfbin_didSJ1pB2w_aem_ZmFrZWR1bW15MTZieXRlcw"
          target="_blank?"
        >
          <FaInstagram />
        </a>
      </li>
      <li>
        <a href="https://wa.me/4152155740" target="_blank?">
          <FaWhatsapp />
        </a>
      </li>
    </StyledSocialIcons>
  );
}
