import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
* {
    box-sizing: border-box;
}
body {
    background: ${({ theme }) => theme.colors.body};
    color: hsl(192, 100%, 9%);
    font-family: 'Poppins', sans-serif;
    font-size: 1.15em;
    margin:0;
}
p, li {
    opacity:0.6;
    line-height:1.5;
}
a {
    text-decoration: none;
    color: ${({ color }) => color || "#333"};
  }
  a:hover {
    opacity: 0.9;
    transform: scale(0.98);
    color: #ab0000;
  }
img {
    max-width:100%;
}
`;

export default GlobalStyles;
