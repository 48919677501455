import { ThemeProvider } from "styled-components";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Card from "./components/Card";
import { Container } from "./components/styles/Container.styled";
import GlobalStyles from "./components/styles/Global";

const theme = {
  colors: {
    header: "#66a2f3",
    body: "#eae7f2",
    footer: "#49bded",
  },
  mobile: "768px",
};
function App() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <Header />
        <Container>
          {/* map the content to create a list  */}

          <Card />
        </Container>
        <Footer />
      </>
    </ThemeProvider>
  );
}

export default App;
